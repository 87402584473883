import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "page-section section-team text-center",
  id: "meeskond"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "page-section-heading text-center text-secondary" }
const _hoisted_4 = { class: "team" }
const _hoisted_5 = { class: "row justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamMember = _resolveComponent("TeamMember")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
            return (_openBlock(), _createBlock(_component_TeamMember, {
              key: member,
              member: member
            }, null, 8, ["member"]))
          }), 128))
        ])
      ])
    ])
  ]))
}