import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamMembers = _resolveComponent("TeamMembers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TeamMembers, {
      title: "Korraldajad",
      members: _ctx.organizers
    }, null, 8, ["members"]),
    _createVNode(_component_TeamMembers, {
      title: "Treenerid",
      members: _ctx.trainers
    }, null, 8, ["members"]),
    _createVNode(_component_TeamMembers, {
      title: "Vabatahtlikud",
      members: _ctx.voluntees,
      class: "page-section-last"
    }, null, 8, ["members"])
  ], 64))
}