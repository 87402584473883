import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_About = _resolveComponent("About")!
  const _component_Team = _resolveComponent("Team")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_About),
    _createVNode(_component_Team),
    _createVNode(_component_Contact)
  ], 64))
}