<template>
  <section class="page-section text-center">
      <div class="container">
        <h3 class="page-section-heading  text-secondary">
          Tantsulaagrist
        </h3>

        <div class="row">
            <div class="col">
              <h5 class="page-section-content">
                  Tamsalu Tantsulaager on augusti alguses Tamsalu ja Tapa
                  valla noortele korraldatud laager, kus iga laagripäev
                  koosneb tantsimisest ning põnevatest õhtustest
                  tegevustest. Laagri meeskond koosneb nii Tamsalus
                  üles kasvanud kui ka mujalt vabatahtlikult abistama
                  tulnud noortest inimestest, kelle eesmärkideks on
                  pakkuda noortele meeldejäävaid emotsioone ja
                  tõestada, et igasühes meist peitub tantsulõvi.
                  Tantsulaagri viimasel päeval toimub suur kontsert, kus
                  esitatakse laagris õpitud tantse.
                  <br />
                  Selle aasta kontsert toimub 4. augustil kell 17.00 Tamsalu Kultuurimajas.
                  Tulge vaatama!
              </h5>
            </div>
        </div>
      </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: { }
})
export default class About extends Vue {}
</script>