import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "team-player col-sm-12 col-md-6" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require(`@/assets/img/team/${_ctx.member.image}`),
      class: "img-rounded img-raised"
    }, null, 8, _hoisted_2),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.member.name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.member.description, (answer, question) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("span", null, [
          _createElementVNode("b", null, _toDisplayString(question), 1)
        ]),
        _createElementVNode("span", null, _toDisplayString(answer), 1)
      ]))
    }), 256))
  ]))
}